import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ExploreDetails from '../components/layouts/explore-details/ExploreDetails'
import todayPickData from '../assets/fake-data/data-today-pick';
import { collections,nfts} from '../../src/data/nft';

const Explore = () => {
    const [collectionDesc, setCollection] = React.useState("Hello World");
    const collection = (coll) => {
        console.log(coll)
      setCollection(coll);
    };
   

    return (
        <div className='explore'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay">
                    <img src="../assets/images/backgroup-secsion/bg4.png" style={{width:330,position:'absolute',left:0,top: '80px'}} />
                    <img src="../assets/images/backgroup-secsion/bg3.png" style={{width:330 ,position:'absolute',right:0,top:100}} />

                </div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">{collectionDesc ? collectionDesc.label : 'Marketplace v1'}</h1>
                            </div>
                            <div className="breadcrumbs style2 text-center">
                                <ul>
                                    {collectionDesc ? 
                                    
                                    <>
                            <li>{ collectionDesc.description} </li>
                                    <li>{collectionDesc.description2}</li>
                                    </>
                                    
                                    : 
                                    
                                    <li>
            
                                        Welcome to Born To Die Game Marketplace v1. <br/>Here you will find the most sought-after and exclusive NFTs, In-Game assets and Collections. </li>
}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <ExploreDetails data={todayPickData} collection={collection} />
            <Footer />
        </div>
    );
}

export default Explore;
