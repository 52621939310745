import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";

const CoinbaseWalletProvider = new WalletLinkConnector({
  url: `https://goerli.infura.io/v3/20102c3988294f0fb5bc5e58ec540671`,
  appName: "Web3-react Demo",
  supportedChainIds: [1, 3, 4, 5, 42],
});

const WalletConnectProvider = new WalletConnectConnector({
  rpc: { 1: "https://goerli.infura.io/v3/20102c3988294f0fb5bc5e58ec540671" },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

const InjectedProvider = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
});

const MetaMask = new InjectedConnector({ supportedNetworks: [1, 4] });

export { CoinbaseWalletProvider, WalletConnectProvider, InjectedProvider, MetaMask };

