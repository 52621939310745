import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import { contractConfig } from '../../config/web3';
import WalletConnectComponent from './WalletConnect.component';
import img1 from '../../assets/images/icon/connect-1.png';
import img4 from '../../assets/images/icon/connect-4.png';
import { useWeb3React } from '@web3-react/core'
import { WalletConnectProvider, CoinbaseWalletProvider, MetaMask } from '../../config/wallets';
import { useNavigate } from 'react-router-dom';

// TODO - Other wallet images
// import img2 from '../../assets/images/icon/connect-2.png';
// import img3 from '../../assets/images/icon/connect-3.png';
import img5 from '../../assets/images/icon/connect-5.png';
// import img6 from '../../assets/images/icon/connect-6.png';
// import img7 from '../../assets/images/icon/connect-7.png';
// import img8 from '../../assets/images/icon/connect-8.png';

const wallets = [
    {
        id: 1,
        img: img1,
        title: 'Meta Mask',
        key: 'metamask',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id: 2,
        img: img5,
        title: 'Coinbase',
        key: 'coinbase',
        description: 'Aliquip sint exercitation occaecat ullamco et.'
    },
    {
        id: 3,
        img: img4,
        title: 'WalletConnect',
        key: 'walletconnect',
        description: 'Ipsum labore deserunt adipisicing id quis ut est mollit Lorem et minim.'
    },
    // {
    //     id: 4,
    //     img: 'https://www.gitbook.com/cdn-cgi/image/width=40,height=40,fit=contain,dpr=1,format=auto/https%3A%2F%2F3632261023-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-legacy-files%2Fo%2Fspaces%252F-MVOiF6Zqit57q_hxJYp%252Favatar-1615495356537.png%3Fgeneration%3D1615495356841399%26alt%3Dmedia',
    //     title: 'Phantom',
    //     key: 'phantom',
    //     description: 'Metus corrupti itaque reiciendis, provident condimentum, reprehenderit numquam, mi'
    // },     
];

const WalletConnectContainer = () => {
    const { activate, library } = useWeb3React();
    const navigate = useNavigate();

    useEffect(() => {
        if(library){
            if(library?.provider?.isMetaMask){
                localStorage.setItem('wallet-provider', 'InjectedProvider');
                navigate('/', {
                    replace: true
                });
            }
        }
    }, [library])
    
    const connectToMetaMask = async () => {
        try{
            await activate(MetaMask);
            localStorage.setItem('metmask-provider', 'MetaMask');
            console.log(localStorage.getItem('metamask-provider'), 'debugging');
            navigate('/', {
                replace: true
            });
        } catch (e) {
            console.log(e);
        }
    }

    const connectToCoinbase = async () => {
        try{
            await activate(CoinbaseWalletProvider);
            localStorage.setItem('wallet-provider', 'CoinbaseWalletProvider');
            navigate('/', {
                replace: true
            });
        } catch (e) {
            console.log(e);
        }
    };

    const connectToPhantom = async () => {
        if (
            window?.phantom?.solana?.isPhantom
        ) {
            try{
                const provider = window?.phantom?.solana;
                await provider?.connect();
                localStorage.setItem('wallet-provider', 'Phantom');
                navigate('/', {
                    replace: true
                });

            } catch (e) {
                console.clear();
                console.log(e);
            }
        }
    };

    const connectToWalletConnect = async () => {
        try{
            await activate(WalletConnectProvider);
            localStorage.setItem('wallet-provider', 'WalletConnectProvider');
            console.log(localStorage.getItem('wallet-provider'), 'debugging');
            navigate('/', {
                replace: true
            });
        } catch(e){
            console.clear();
            console.log(e);
        }
    }

    const onWalletConnect = (provider) => {
        if(provider === 'metamask')
            connectToMetaMask()
        if(provider === 'coinbase')
            connectToCoinbase()
        if(provider === 'phantom')
            connectToPhantom()
        if(provider === 'walletconnect')
            connectToWalletConnect()
    }

    const showErrorMessage = message => {
        NotificationManager.error(message, "Error", 5000);
    };

    const showSuccessMessage = message => {
        NotificationManager.success(message, "Message", 3000);
    };

    const showInfoMessage = message => {
        NotificationManager.info(message, "Info", 3000);
    };

    const handledProps = {
        wallets,
        onWalletConnect
    };
    return <>
        <WalletConnectComponent {...handledProps}/>
        <NotificationContainer />
    </>;
}

export default WalletConnectContainer;
