import React, { useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import liveAuctionData from "../../assets/fake-data/data-live-auction";
import LiveAuction from "../../components/layouts/LiveAuction";
import img1 from "../../assets/images/avatar/avt-3.jpg";
import img2 from "../../assets/images/avatar/avt-11.jpg";
import img3 from "../../assets/images/avatar/avt-1.jpg";
import img4 from "../../assets/images/avatar/avt-5.jpg";
import img5 from "../../assets/images/avatar/avt-7.jpg";
import img6 from "../../assets/images/avatar/avt-8.jpg";
import img7 from "../../assets/images/avatar/avt-2.jpg";
import imgdetail1 from "../../assets/images/box-item/images-item-details.jpg";
import SocialShare from "../../components/common/SocialShare";
import { useWeb3React } from "@web3-react/core";
const ItemDetailsComponent = ({
  nftDeatils,
  collectionDetails,
  buyButtonClicked,
  shareSuccessNFT,
  onDismissSocialShare,
}) => {
  const { active, account } = useWeb3React();
  const [dataHistory] = useState(
    [
      {
        img: img1,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 ETH",
        priceChange: "$12.246"
      },
      {
        img: img2,
        name: "Mason Woodward",
        time: "at 06/10/2021, 3:20 AM",
        price: "4.89 ETH",
        priceChange: "$12.246"
      },
      {
        img: img3,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 ETH",
        priceChange: "$12.246"
      },
      {
        img: img4,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 ETH",
        priceChange: "$12.246"
      },
      {
        img: img5,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 ETH",
        priceChange: "$12.246"
      },
      {
        img: img6,
        name: "Mason Woodward",
        time: "8 hours ago",
        price: "4.89 ETH",
        priceChange: "$12.246"
      },
    ]
  )


  const [quantity, setQuantity] = useState(1)

  const add = () => {

  }
  return (
    <div className='item-details'>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row justify-content-md-center">
            <div className="col-md-9">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">{collectionDetails?.label}</h1>
              </div>
              <div className="breadcrumbs style2 center">
                <ul>
                  <li>
                    {collectionDetails?.description}


                  </li>
                  <li>{collectionDetails?.description2}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-section tf-item-details">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <img src={nftDeatils?.img} alt="Axies" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <h2 className="style2">{nftDeatils?.title}</h2>
                  <div className="meta-item">

                  </div>
                  <div className="client-infor sc-card-product">

                    <div className="meta-info">
                      <div className="author">
                        {/* <div className="avatar">
                                                <img src={img7} alt="Axies" />
                                            </div> */}
                        <div className="info">
                          <span>Collection</span>
                          <h6>{collectionDetails?.label}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    {nftDeatils?.description}
                  </p>
                  <p>
                    {nftDeatils?.description2}
                  </p>
                  <div className="meta-item-details style2">
                    <div className="item meta-price">
                        <span className="heading">Price</span>
                        <div className="price">
                            <div className="price-box">
                                <h5>{nftDeatils.price.length == 0 ? 'Coming soon' : `${nftDeatils?.price} ${nftDeatils?.priceChangeCurrency}`}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="item item-quantity">
                      <span className="counter">
                        <button type="button" onClick={() => { if(quantity > 1){setQuantity(quantity - 1)} }} className="quantity-left-minus" data-type="minus" data-field="" >
                          <i className="fa fa-minus"></i>
                        </button>
                      </span>
                      <span className="counter-viewer">{quantity ? quantity : 0}</span>
                      <span className="counter">
                        <button type="button" onClick={() => {  if(quantity < 5){setQuantity(quantity + 1)} }} className="quantity-right-plus" data-type="plus" data-field="">
                          <i className="fa fa-plus"></i>
                        </button>
                      </span>
                    </div>
                    
                  </div>
                  {
                    active && (
                    <div className="wallet-account-container">
                      <p>Connected Wallet Account</p>
                      <div className="item wallet-account">
                        <button type="button" className="transparent">{account}</button>
                      </div>
                    </div>
                  )}
                  <Link onClick={buyButtonClicked} className="sc-button loadmore style fl-button pri-3">
                    <span>{parseInt(nftDeatils.price) > 0 ? nftDeatils.price*quantity + ' ' +nftDeatils?.priceChangeCurrency + ' - MINT' : 'Coming soon' }</span>
                  </Link>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { shareSuccessNFT && <SocialShare onDismiss={onDismissSocialShare} /> }
      {/* <LiveAuction data={liveAuctionData} /> */ }
      <Footer />
    </div>
  );
};

export default ItemDetailsComponent;
