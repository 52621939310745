import React from "react";
import styled from 'styled-components';
import {TwitterShareButton, TwitterIcon} from 'react-share';

const SocialShareWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SocialShareBox = styled.div`
    width: 350px;
    background: white;
    border-radius: 6px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

    &:nth-child(n): {
        margin-bottom: 12px;
    }
`;

const Divider = styled.div`
    height: 12px
`;

const SocialShare = ({onDismiss}) => {
    return <SocialShareWrapper onClick={onDismiss}>
        <SocialShareBox onClick={(e) => e.stopPropagation()}>
            <h4>Awesome ✅</h4>
            <Divider />
            <h6>Let us celebrate it with your connections</h6>
            <Divider />
            <a href='http://twitter.com/share?text=text' target='_blank'>
                <TwitterIcon size={32} round={true}/>
            </a>
            <Divider />
            <button className="btn btn-secondary btn-lg" onClick={onDismiss}>
                CLOSE
            </button>
        </SocialShareBox>
    </SocialShareWrapper>
}

export default SocialShare;