import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useState } from "react";
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#000',
    fontSize: '24px'
  },
};

const WalletConnect = ({ wallets = [], onWalletConnect }) => {
  const [walletError, setWalletError] = useState('');
  const [WalletImage, setWalletImage] = useState('');
  const [referenceLink, setReferenceLink] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);

  const onWalletClick = (key, image) => {
    if(key === 'metamask' && (typeof window.ethereum == "undefined" || !window.ethereum.isMetaMask))
    {
      setIsOpen(true);
      setWalletError('Add meta mask to your browser');
      setWalletImage(image);
      setReferenceLink('https://metamask.io/');
      return false;
    }
    onWalletConnect(key);
  }
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Connect Wallet</h1>
              </div>
              {/* <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Pages</Link>
                  </li>
                  <li>Connect Wallet</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="tf-connect-wallet tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-2 mg-bt-12">
                Connect Your Wallet
              </h2>
              <h5 className="sub-title ct style-1 pad-400">
              Unlock the door to a world of unique digital assets. Connect your wallet, secure your BTDG exclusive NFTs, and start your collection on Born to Die today.
              </h5>
            </div>
            <div className="col-md-12">
              <div className="sc-box-icon-inner style-2 justify-content-md-center">
                {wallets?.map((item) => (
                  <div
                    key={item?.id}
                    className="sc-box-icon"
                    onClick={() => {
                      onWalletClick(item?.key, item?.img);
                    }}
                  >
                    <div className="img">
                      <img src={item?.img} alt="Axies" style={{ width: 50 }} />
                    </div>
                    <h4 className="heading">{item?.title} </h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {setIsOpen(false)}}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        <div className="wallet-modal modal-body">
          <div className="img">
            <img src={WalletImage} alt="Axies" style={{ width: 50 }} />
          </div>
          <div className="message">{walletError}</div>
          {
            referenceLink && (<p className="reference-link">
            <a href="https://metamask.io/" target="_blank">Click Here to download</a>
          </p>)
          }
        </div>
      </Modal>
    </div>
  );
};

export default WalletConnect;
