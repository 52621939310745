import React, { useState, useEffect ,Fragment} from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CardModal from "../CardModal";
import { collections, nfts } from "../../../data/nft";

const TodayPicks = ( {collection} ) => {
  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };
  const [modalShow, setModalShow] = useState(false);
const [selectedCollection,setCollection]=useState()
let msg = 'Goodbye';


useEffect(() => {
   
    collection(selectedCollection)
  },[selectedCollection]);
  const renderNfts = () => {
    return collections?.map((collection) => {
      const filteredNfts = nfts?.filter?.(
        (nft) => nft.collectionId === collection.id || collection.id === 0
      );


      return (
        <TabPanel key={collection.id}>
          {filteredNfts?.map((item) => (
            <div
              key={item.id}
              className={`sc-card-product explode style2 mg-bt ${
                item.feature ? "comingsoon" : ""
              }`}
            >
              <div className="card-media" style={{ backgroundColor: "white" }}>
                <Link to={`/item-details/${item.id}`}>
                  <img src={item.img} alt="" />
                </Link>
                {/* <div className="button-place-bid">
                                <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Place Bid</span></button>
                            </div> */}
                <div className="coming-soon">{item.feature}</div>
              </div>
              <div className="card-title">
                <h5>
                  <Link to={`/item-details/${item.id}`}>{item.title}</Link>
                </h5>
              </div>
              <div className="card-bottom style-explode">
                <div className="price">
                  <span>Price</span>
                  <div className="price-details">
                    <h5>{item.price.length == 0 ? 'coming soon' : ` ${item?.price} ${item?.priceChangeCurrency}`}</h5>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* {visible < filteredNfts?.length && (
            <div className="col-md-12 wrap-inner load-more text-center">
              <Link
                to="#"
                id="load-more"
                className="sc-button loadmore fl-button pri-3"
                onClick={showMoreItems}
              >
                <span>Load More</span>
              </Link>
            </div>
          )} */}
        </TabPanel>
      );
    });
  };

  return (
    <Fragment>
      <div className="tf-section sc-explore-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="flat-tabs explore-tab">
                <Tabs>
                  <TabList>
                    {collections.map((collection) => (
                      <Tab
                        key={collection.id}
                        value={collection.id}
                        onClick={() => setCollection(collection)}
                      >
                        {collection.label}
                      </Tab>
                    ))}
                  </TabList>
                  {renderNfts()}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
};

export default TodayPicks;
