export const collections = [
    {
        id: 0,
        label: 'All',
        name: 'all',
    },
    // {
    //     id: 1,
    //     label: 'Born To Die Alpha',
    //     name: 'born_to_die_alpha',
    //     description: '🎮 #BornToDieGame is an AAA-class 3D-shooter game 🔫 in the metaverse featuring rich graphics, an advanced AI-engine & open-world gameplay. Mission is to collect portal keys to unlock teleportation, time-travel & save humanity.'
    // },
    {
        id: '0A',
        label: 'GENESIS',
        name:'GENESIS',
        description: 'The Genesis NFTs of Born To Die.',
        description2:'As a holder of a Genesis NFT, you will have exclusive early access to play the game before it is released to the public. Also gives you early access to in-game assets. One time opportunity with limited quantities!',
    },
    {
        id: '0B',
        label: 'HEROES',
        name:'HEROES',
        description: "The heroes in Born to Die are a diverse group of powerful warriors, each with their own unique abilities and fighting styles. They are united in their mission to save the world from an impending doom and save humanity.",
        description2:'Players can choose from a variety of different heroes, each with their own special traits and abilities. Some may have increased strength, while others may have special powers. Each hero has their own unique backstory and personality, making them a compelling and dynamic character to play as. With their combination of skill, courage, and determination, these heroes are sure to inspire players as they take on the challenges of the game.'
    },
    {
        id: "0C",
        label: "WEAPONS",
        name:'WEAPONS',
        description: "The weapons in the game are an essential part of the player's arsenal in their fight to save humanity. The game features a wide variety of weapons, each with their own unique characteristics and abilities.",
        description2: "Players can choose from a variety of weapons each with varying levels of damage and speed. Some weapons may also have special abilities such as elemental damage, increased critical hit chance, or the ability to slow enemies. These weapons allow players to tailor their fighting style to their preferences and the situation they find themselves in. The weapons are beautifully designed, with intricate details and animations that add to the immersion of the game and help add to your gaming experience."
       }
];

export const nfts = [
    // {
    //     id: 0,
    //     img: 'https://www.photohdx.com/images/2017/04/clean-white-paper-background-texture.jpg',
    //     title: "BTDG Alpha #026",
    //     description: '🎮 #BornToDieGame is an AAA-class 3D-shooter game 🔫 in the metaverse featuring rich graphics, an advanced AI-engine & open-world gameplay. Mission is to collect portal keys to unlock teleportation, time-travel & save humanity.',
    //     collectionId: 1,
    //     nameAuthor: "SalvadorDali",
    //     price: "12.246",
    //     priceCurrency: '$',
    //     priceChange: '0.000001',
    //     priceChangeCurrency: 'ETH',
    //     feature: null,
    // },
    {
        id: 1,
        collectionId: "0A",

        title: "BTDG ALPHA",
        description: "Born To Die ALPHA is an exclusive, invite-only non-fungible token (NFT) that is available to members of the community who were involved in the creation, development, design, and support of the game. This NFT serves as a symbol of appreciation and gratitude for their valuable feedback. There are many utilities of the Alpha holders, including early access to play the main game on launch.",
        price: "INVITE ONLY",
        img: "../assets/images/nft/alpha.png",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'ETH',
       },
              {
        id: 4,
        collectionId: "0A",
        nameAuthor: "AAA",
        title: "AAA - EMERALD",
        description: "The AAA Emerald card provides players with access to all of the game's assets, including Heroes, Weapons, Skins, and Maps. Additionally, players who own this card will also have access to the beta version of the game, special events and tournameAuthornts, and early access to play the main game on launch.",
        price: "2.5",
        img: "../assets/images/nft/AAA(3).png",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'ETH',
       },
       {
        id: 3,
        collectionId: "0A",
        nameAuthor: "AAA",
        title: "AAA - GOLD",
        description: "The AAA Gold card offers players access to a variety of in-game assets, including a hero NFT, weapons, skins, and maps. Additionally, it grants players access to the beta version of the game, special events and tournameAuthornts, and early access to play the main game on launch.",
        price: "1",
        img: "../assets/images/nft/AAA(5).png",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'ETH',
       },
       {
        id: 2,
        collectionId: "0A",
        name: "AAA",
        title: "AAA - SILVER",
        description: "The AAA Silver card grants players access to a limited selection of the game's assets, such as weapons, skins, and maps. Additionally, it provides access to the beta version, some events and tournameAuthornts, and early access to play the main game on launch.",
        price: "0.25",
        img: "../assets/images/nft/AAA(1).png",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'ETH',
       },
       {
        id: 5,
        collectionId: "0A",
        name: "AAA",
        title: "AAA - VIP",
        description: "The AAA VIP NFT, from the Genesis collectionId, gives the holder Future Assets. It also grants players access to the beta version of the game and early access to play the main game on launch. The investors get a limited selection of in-game assets, such as weapons, skins, and maps. ",
        price: "0.1",
        img: "../assets/images/nft/AAA(2).png",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'ETH',
       },
       
       

    //    {
    //     id: 6,
    //     collectionId: "0A",
    //     nameAuthor: "AAA",
    //     title: "GAME",
    //     description: "The AAA Game card grants gamers early access to play the main game on launch, and a 2x game credit for gameplay.",
    //     price: "10 BUSD",
    //     img: "../assets/images/nft/AAA(4).png",
    //     priceCurrency: '$',
    //     priceChange: '0.000001',
    //     priceChangeCurrency: 'BUSD',
    //    },
       {
        id: 7,
        collectionId: "0B",
        nameAuthor: "HUMANS",
        title: "CIPHER",
        description: "Cipher is the hero known for his agility and sharpness in battle, as well as his deadly combat moves. His special traits include higher health, more stamina, and lots of energy, which make him a formidable opponent in the game.",
        description2:"Cipher's abilities allow him to outmaneuver and outlast his enemies, making him a valuable asset in any fight. With his unique skills and abilities, Wesley is a powerful force to be reckoned with.",
        price: "",
        img: "../assets/images/nft/HC(2).jpg",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'TBD',
       },
       {
        id: 8,
        collectionId: "0B",
        nameAuthor: "HUMANS",
        title: "ROGUE",
        description: "Rogue is known for her quickness and strength in battle, as well as her fast combat moves. Her special traits include more power, and lots of energy allowing her to outmaneuver and outpower her enemies, making her a valuable asset in any fight.",
        description2:"Rogue is able to swiftly take down enemies with her fast and powerful moves, and her high health and energy levels allow her to keep up the pace of battle.",
        price: "",
        img: "../assets/images/nft/HC(4).jpg",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'TBD',
       },
       {
        id: 9,
        collectionId: "0B",
        nameAuthor: "HUMANS",
        title: "KOKAINE",
        description: "Kokaine is a formidable warrior with an arsenal of powerful combat moves. His unique abilities include superior speed, more punch power, and a points multiplier that allows him to earn more points during gameplay.",
        description2: "With these special traits and upgrades , no challenge would be too challenging for Kokaine!",
        price: "",
        img: "../assets/images/nft/HC(3).jpg",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'TBD',
       
       },
       {
        id: 10,
        collectionId: "0B",
        nameAuthor: "HUMANS",
        title: "TRINITY",
        description: "Trinity is a powerful combatant with unique game style. Her special traits include increased speed, enhanced stamina, and a points multiplier, making her an unstoppable force on the battlefield.",
        description2: "With her combination of agility and endurance, Trinity is able to outmaneuver her opponents and take them down with ease.",
        price: "",
        img: "../assets/images/nft/HC(1).jpg",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'TBD',
        
       },
       {
        id: 11,
        collectionId: "0C",
        nameAuthor: "KNIFE",
        title: "KOPIS",
        description: "known for its sharp and deadly blade, and its sleek and elegant design, the Kopis is a versatile weapon that can be used for both close-quarters combat and stealth kills.",
        description2:"Its lightweight construction and comfortable grip makes it more long-lasting and does not take up a lot of inventory space. This could be your go-to weapon if you run out of ammo and can be reliable and handy. They need to be maintained regularly to keep their edges sharp and attack powerful.",
        price: "",
        img: "../assets/images/nft/W(1).jpg",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'TBD',
       },
       {
        id: 12,
        collectionId: "0C",
        nameAuthor: "PISTOL",
        title: "CHAIMERA 12 PISTOL",
        description: "The Chaimera 12 will most likely be your first pistol. It is known for its accuracy, and fast reload. These are best used in middle range combat, and\/or when you want to kill the enemies in semi-stealth mode.",
        description2:"The weapon's sleek design and durable construction make it a reliable choice for any situation. Its recoil is manageable, and it has low maintenance.",
        price: "",
        img: "../assets/images/nft/W(2).jpg",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'TBD',
       },
       {
        id: 13,
        collectionId: "0C",
        nameAuthor: "RIFLE",
        title: "MDKITE 29 ASSAULT RIFLE",
        description:"The MDKITE 29 Assault Rifle is must-have in your arsenal.They make missions easier with its long-range and large magazine capacity. The rifle's design is sleek and compact, making it easy to handle and maneuver in combat.",
        description2:"The MDKITE 29 has a good rate of fire, and it's a balanced weapon, suitable for different playing styles. It has a good recoil control, and it's damage is moderate. The rifle can be upgraded to increase its abilities, such as accuracy, damage, and fire rate, making it an excellent choice for taking on enemies at long range and in close combat.",
        price: "",
        img: "../assets/images/nft/W_3.jpg",
        priceCurrency: '$',
        priceChange: '0.000001',
        priceChangeCurrency: 'TBD'
       }
];
