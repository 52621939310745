import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import routes from "./pages/index";
import { useWeb3React } from "@web3-react/core";
import {
  CoinbaseWalletProvider,
  InjectedProvider,
  WalletConnectProvider,
  MetaMask,
} from "./config/wallets";

function App() {
  const { activate } = useWeb3React();

  useEffect(() => {
    const provider = localStorage.getItem("wallet-provider");
    try {
      if (provider === "InjectedProvider") {
        activate(InjectedProvider);
      }
      if (provider === "CoinbaseWalletProvider")
        activate(CoinbaseWalletProvider);
      if (provider === "Phantom" && window?.phantom?.solana?.isPhantom)
        window?.phantom?.solana?.connect();
      if (provider === "WalletConnectProvider") activate(WalletConnectProvider);
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (window?.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        if (accounts?.length === 0) {
          localStorage.removeItem("wallet-provider");
        }
      });
    }
  }, []);

  return (
    <Routes>
      {routes.map((data, index) => (
        <Route
          onUpdate={() => window.scrollTo(0, 0)}
          exact={true}
          path={data.path}
          element={data.component}
          key={index}
        />
      ))}
    </Routes>
  );
}

export default App;

