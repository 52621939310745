import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ItemDetailsComponent from './ItemDetails.component';
import {nfts as NFTDetails, collections} from '../../data/nft';
import {ethers} from 'ethers';
import {contractConfig} from '../../config/web3';

const ItemDetailsContainer = () => {
    const params = useParams();
    const nftId = params?.id;
    const nftDeatils = NFTDetails?.find(entry => entry.id == nftId);
    const collectionDetails = collections?.find(entry => entry.id == nftDeatils?.collectionId);
    const [shareSuccessNFT, setShareSuccessNFT] = useState();

    const buyButtonClicked = async () => {
        if (
            typeof window.ethereum !== "undefined" &&
            window.ethereum.isMetaMask
        ) {
            try{
                const provider = window["ethereum"];
                await provider.enable();
                const walletProvider = new ethers.providers.Web3Provider(window.ethereum);
                const walletSigner = walletProvider.getSigner();
                // const userAddress = await walletSigner.getAddress();

                const contract = new ethers.Contract(
                    contractConfig.address,
                    contractConfig.abi,
                    walletSigner,
                );
                const response = await contract.mintNFT('https://ipfs.io/ipfs/QmYJx1uffuxvgXeurHmF7sHoA9SGdy77Nm6yEqZ64VyndS', {value: ethers.utils.parseEther("0.0001")})
                setShareSuccessNFT(response);
            } catch (e) {
                console.clear();
                console.log(e);
            }
        } else {
        }
    };

    if(!nftId || !nftDeatils || !collectionDetails) return;

    return <ItemDetailsComponent {...{
        nftDeatils,  
        collectionDetails, 
        buyButtonClicked,
        shareSuccessNFT,
        onDismissSocialShare: (e) => setShareSuccessNFT(),
    }}/>;
}

export default ItemDetailsContainer;